nav{
  position: fixed;
  width: 100%;
  top: 0;
  height: 100px;
  background: $c1;
  @include flex;
  &>div{
    @include flex;
    position: relative;
    .menu{
      width: 40px;
      min-width: 40px;
      margin: 0 5%;
      cursor: pointer;
      display: none;
    }
    .love{
      width: 40px;
      margin: 0 5%;
      cursor: pointer;
      display: none;
    }
    &:nth-child(1){
      justify-content: flex-start;
      width: 100%;
      padding-left: 2%
    }
    &:nth-child(2){
      z-index: 1;
      justify-content: flex-end;
      padding-right: 2%;
      min-width: 180px;
      width: 180px;
    }
    &>div{
      &>figure{
        width: 80px;
        height: 80px;
        border-radius: 50%;
        background: white;
        @include flex;
        font-size: 2em;
        overflow: hidden;
        img{
          max-width: 150%;
          max-height: 150%;
        }
      }
    }
  }
  .logo{
    font-size: 7px;
    width: 360px;
    &>div{
      margin-top: -5px;
      &>figure{
        top: 0
      }
      h3{
        margin-top: -5px
      }
    }
    &>figure{
      img{
        width: 80px
      }
    }
  }

}


@media screen and (max-width: 1600px) {}

@media screen and (max-width: 1400px) {}

@media screen and (max-width: 1300px) {}

@media screen and (max-width: 1200px) {}

@media screen and (max-width: 1024px) {
}

@media screen and (max-width: 900px) {
}

@media screen and (max-width: 760px) {
  nav{
    z-index: 2
  }
  nav > div .love{
    display: flex;
  }
  nav > div .menu{
    display: flex;
  }
}

@media screen and (max-width: 600px) {
  nav{
    .logo{
      font-size: 6px;
      width: 250px;
    }
  }
  nav .logo > div > figure{
    top: -20px;
    right: -40px;
  }
  .logo > div{
    width: 180px
  }  
  nav > div > div > figure{
    width: 50px;
    height: 50px;
  }
}

@media screen and (max-width: 550px) {
  nav{
    .logo{
      font-size: 5px;
      width: 220px;
    }
  }
}

@media screen and (max-width: 400px) {}

@media screen and (max-width: 320px) {}
