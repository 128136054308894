.template{
  @include flex;
  flex-wrap: wrap;
  h1{
    width: 100%;
    margin: 10px 0
  }
  &>div{
    @include flex;
    width: 100%;
    margin: 10px 0
  }
  button{
    margin: 10px 0
  }
}
