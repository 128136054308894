.App > .home {
  height: calc(100vh);
  padding-top: 100px;
  overflow: hidden;
  @include flex;

  & > aside {
    width: 20%;
    height: 100%;
    overflow-y: auto;

    ul {
      padding: 0 5%;

      li {
        width: fit-content;
        width: 100%;
        font-size: 1.4em;
        text-align: left;
        cursor: pointer;
        position: relative;
        @include flex;
        justify-content: flex-start;

        figure {
          @include flex;
          margin-left: 5px;
          margin-top: 5px;
          display: none;

          svg {
            width: 20px;
          }
        }

        &:last-child {
          margin-bottom: 20px;
        }

        &:hover {
          figure {
            display: flex;
          }
        }
      }
    }
  }

  & > article {
    width: 60%;
    height: 100%;
    @include flex;
    flex-wrap: wrap;
    align-items: flex-start;

    header {
      width: 100%;
      padding: 10px 5%;

      h2 {
        color: $c1;
        text-align: left;
        font-size: 2.2em;
      }
    }

    & > aside {
      height: calc(100% - 30px);
      width: 90%;
      overflow-y: auto;
      overflow-x: hidden;
      header {
        margin-top: 40px;
        margin-bottom: 30px;
        @include flex;
      }

      &>section {
        padding: 0 15%;
        div {
          margin-bottom: 40px;
          &>h3 {
            margin-bottom: 15px;
          }
          p {}
        }
      }

      footer {
        margin-top: 40px;
        p {
          font-weight: bold;
          font-size: 1.2em
        }
      }
    }

    & > section {
      width: 100%;
      padding: 0 5%;
      margin-bottom: 40px;

      & > section {
        & > aside {
          overflow-x: auto;
          margin-top: 10px;

          & > ul {
            display: inline-flex;
            justify-content: flex-start;

            li {
              width: 150px;
              margin-right: 20px;
              position: relative;

              header {
                width: 100%;
                position: absolute;
                top: 0;
                height: 30px;
                @include flex;
                justify-content: flex-end;

                figure {
                  @include flex;
                  width: 20px;
                  height: 20px;
                  background: red;
                  font-size: 11px;
                  border-radius: 50%;
                  color: white;
                  font-weight: 400;
                  cursor: pointer;
                }
              }

              figure {
                @include flex;
                height: 100%;

                img {
                  max-width: 105%;
                }
              }
            }
          }
        }

        & > div {
          width: 100%;
          @include flex;

          figure {
            width: 70%;

            img,
            video {
              max-width: 105%;
            }
          }
        }
      }

      &>div {
        margin-bottom: 20px;

        input {
          width: 100%;
          height: 30px;
          padding: 20px;
          border: none;
          font-size: 1.6em;
        }

        textarea {
          width: 100%;
          padding: 20px;
          border: 1px dashed gray;
          resize: none;
        }
      }

      article {
        @include flex;
        margin: 30px 0;

        div {
          width: 120px;
          margin: 0 40px;
          position: relative;

          input {
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            opacity: 0;
            cursor: pointer;
          }

          figure {}

          p {}
        }
      }

      footer {
        @include flex;
        justify-content: center;

        .btn2 {}
      }
    }

    & > article {
      width: 97%;
      padding: 0 2%;      
      position: relative;
      border: 1px solid #eee;
      padding-top: 20px;
      margin-bottom: 40px;
      h4 {
        text-align: left;
        width: 100%;
        font-size: 1.6em;
      }

      & > h5 {
        position: absolute;
        top: 0;
        right: 10px;
        font-size: 1.2em;
        font-weight: 400;
      }

      figure {
        width: 80%;
        margin: 0 10%;
        @include flex;

        img {
          max-width: 105%;
        }
      }

      p {
        text-align: left;
        font-size: 1em;
        margin-top: 10px;
      }

      &>div {
        padding: 0 2%;
        margin-top: 30px;

        h5 {
          text-align: left;
          width: 100%;
          font-size: 1.2em;
          margin-bottom: 20px;
          font-weight: bold;
        }

        figure {}

        article {
          overflow-x: auto;
          margin-top: 10px;
        }

        ul {
          display: inline-flex;
          justify-content: flex-start;

          li {
            width: 150px;
            margin-right: 20px;

            figure {}
          }
        }
      }

      footer {
        padding: 0 2%;
        margin-top: 30px;

        h5 {
          text-align: left;
          width: 100%;
          font-size: 1.2em;
          margin-bottom: 20px;
          font-weight: bold;
        }

        figure {
          width: 80%;
          margin: 0 10%;
          @include flex;

          img,
          video {
            max-width: 105%;
          }
        }

        p {
          width: 100%;
          text-align: center;
          margin-top: 40px;
          padding-bottom: 20px;
        }
      }

      &:last-child {
        margin-bottom: 50px;
      }
    }

    &:nth-child(3) {
      width: 20%;
      flex-direction: column;
      justify-content: flex-start;
      flex-wrap: nowrap;

      h3 {
        color: $c1;
        text-align: center;
        width: 100%;
        font-size: 1.7em;
        margin-top: 10px;
        margin-bottom: 10px;
      }

      & > ul {
        width: 100%;
        margin-bottom: 50px;
        padding: 0 2%;

        li {
          width: fit-content;
          width: 100%;
          font-size: 1.4em;
          text-align: left;
          cursor: pointer;
          position: relative;
          @include flex;
          justify-content: flex-start;

          figure {
            @include flex;
            margin-left: 5px;
            margin-top: 5px;
            display: none;

            svg {
              width: 20px;
              fill: red;
            }
          }

          &:last-child {
            margin-bottom: 20px;
          }

          &:hover {
            figure {
              display: flex;
            }
          }
        }
      }

      & > section {
        h4 {
          color: $c1;
          text-align: center;
          font-size: 1.5em;
          margin-top: 10px;
        }

        & > ul {
          width: 100%;
          margin-top: 20px;

          li {
            width: fit-content;
            width: 100%;
            font-size: 0.7em;
            text-align: center;
            cursor: pointer;
            position: relative;
            @include flex;
            justify-content: center;
            height: 20px;

            figure {
              @include flex;
              margin-left: 5px;
              margin-top: 5px;
              display: none;

              svg {
                width: 20px;
                fill: red;
              }
            }

            &:last-child {
              margin-bottom: 20px;
            }

            &:hover {
              p {
                font-weight: bold;
              }

              figure {
                display: flex;
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1600px) {}
@media screen and (max-width: 1400px) {}
@media screen and (max-width: 1300px) {}
@media screen and (max-width: 1200px) {}
@media screen and (max-width: 1024px) {
  .App > .home > article > aside header{
    .logo{
      font-size: 10px;
      width: 220px;
    }
  }
}
@media screen and (max-width: 900px) {
  .App > .home > article > aside header{
    .logo{
      font-size: 9px;
      width: 220px;
    }
  }
}
@media screen and (max-width: 760px) {
  .App > .home {
    position: relative;
    z-index: 1;
  }

  .App > .home > article {
    width: 100%;
  }

  .App > .home > aside {
    position: absolute;
    left: -100vw;
    top: 100px;
    height: calc(100vh - 100px);
    width: 100vw;
    z-index: 1;
    background: white;
    transition: all 0.5s;

    &.active {
      left: 0;
      transition: all 0.5s;
    }
  }

  .App > .home > article:nth-child(3) {
    background: white;
    position: absolute;
    left: 100vw;
    top: 100px;
    height: calc(100vh - 100px);
    width: 100vw;
    z-index: 1;
    transition: all 0.5s;

    &.active {
      left: 0;
      transition: all 0.5s;
    }
  }

  .App > .home > article:nth-child(3) > ul li p {
    text-align: center;
    width: 100%;
  }
  .App > .home > article > aside{
    width: 100% ;
  }

  .App > .home > aside ul li p {
    text-align: center;
    width: 100%;
  }
  .App > .home > aside ul li{
    justify-content: center;
  }
  .App > .home > aside ul li p{
    width: fit-content;
    margin-right: 10px;
  }
  .App > .home > aside ul li figure{
    display: flex;
  }
}
@media screen and (max-width: 600px) {
  
}
@media screen and (max-width: 550px) {}
@media screen and (max-width: 400px) {}
@media screen and (max-width: 320px) {}
