$c1: #4EAC6E;

::-webkit-scrollbar{
  width: 5px
}
::-webkit-scrollbar-track{
  background: #f1f1f1
}
::-webkit-scrollbar-thumb{
  background: $c1
}
::-webkit-scrollbar-thumb:hover{
  background: #555
}

*{
  padding: 0;
  margin: 0;
  list-style: none;
  box-sizing: border-box;
}

html, body{
  overflow: hidden;
}

.btn1{
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  border: 1px solid white;
  padding: 15px 50px;
  background: white !important;
  margin: 0 10px;
  cursor: pointer;
  font-weight: bold;
}
.btn2{
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  border: 1px solid $c1;
  padding: 15px 50px;
  background: $c1 !important;
  margin: 0 10px;
  color: black;
  cursor: pointer;
  font-weight: bold;
}

.App{
  &>section{
    overflow: hidden;
    height: 100%;
    &>article{
      overflow-y: auto;
      @include flex;
    }
  }
}

.logo{
  width: 100%;
  font-size: 12px;
  @include flex;
  max-width: 430px;
  cursor: pointer;
  &>figure{
    img{
    }
  }
  &>div{
    position: relative;
    @include flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    h1{
      font-size: 5em;
      width: 100%;
    }
    h3{
      font-weight: 400;
      font-size: 2em;
      margin-top: -15px;
      width: 120px;
      margin-right: 15%;
      white-space: nowrap;
    }
    figure{
      position: absolute;
      top: -30px;
      right: 0;
      img{
      }
    }
  }
}

.ck-editor__editable_inline {
    min-height: 20vh;
}
.ck-powered-by{
  display: none !important;
}

.swal2-modal{
  background: rgba($c1,0.9);
  border: $c1 solid 3px;
  h2{
    font-size: 1.4em;
    color: white;
    margin-bottom: 20px;
  }
  &>div{
    button{
      font-size: .9em;
    }
  }
  .swal2-actions{
    @include flex;
    flex-wrap: wrap;
    width: 290px;
  }
  .swal2-confirm{
    background: $c1;
    border: 1px solid white
  }
}

.ck.ck-dropdown .ck-button.ck-dropdown__button:not(.ck-button_with-text){
  z-index: 0;
}
.ck.ck-dropdown .ck-dropdown__arrow{
  z-index: 0;
}