.profile {
  @include flex;
  flex-wrap: wrap;
  &>header {
    height: 4vh;
    width: 100%;
    background: $c1;
    @include flex;
    flex-wrap: wrap;
    position: relative;
    h3{
      position: absolute;
      width: 100%;
      padding: 2px 5%;
      text-align: left;
      top: 60px;
      font-size: 1.9em;
    }
    h4{
      position: absolute;
      right: 10px;
      width: fit-content;
      padding: 0 20px;
      cursor: pointer;
      padding: 2px 5%;
      font-size: 1.1em;
    }
  }

  &>article {
    height: 96vh;
    width: 100%;
    @include flex;
    aside {
      order: 2;
      width: 100%;
      @include flex;
      flex-wrap: wrap;
      header {
        @include flex;
        width: 80%;
        img {}
      }
      article {
        h3 {
          font-weight: 400
        }
      }
    }

    &>section {
      order: 1;
      width: 100%;
      @include flex;
      flex-wrap: wrap;
      form {
        padding: 30px 5%;
        border-radius: 20px;
        width: 80%;
        header {
          @include flex;
          margin-bottom: 10px;
          div {
            font-size: 1.4em;
            font-weight: 400;
            width: 100%;
            cursor: default;
            span{
              cursor: pointer;
              &:last-child{
                font-weight: bold;
              }
            }
          }
          figure{
            width: 120px;
            height: 120px;
            min-width: 120px;
            min-height: 120px;
            margin-left: 10px;
            border-radius: 10px;
            background: $c1;
            position: relative;
            @include flex;
            border: 1px solid #ccc;
            overflow: hidden;
            input{
              position: absolute;
              width: 100%;
              height: 100%;
              opacity: 0;
              z-index: 1;
              left: 0;
              top: 0
            }
            img{
              position: absolute;
              max-width: 105%;
              max-height: 105%;
            }
          }
        }
        &>div {
          @include flex;
          width: 100%;
          height: 50px;
          margin-bottom: 15px;
          &>label{
            min-width: 30%;
            width: 30%;
            text-align: left;
            font-size: 1.2em
          }
          &>div {
            width: 100%;
            @include flex;
            justify-content: flex-start;
            &>input {
              border: 1px solid black;
              padding-left: 15px;
            }
            label{
              margin-right: 10px
            }
          }
          &>input {
            width: 100%;
            height: 50px;
            border: 1px solid black;
            padding-left: 15px;
          }
        }
        footer {
          @include flex;
          margin-top: 30px;
          .btn1 {}
        }
      }

    }
  }
}
