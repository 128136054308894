
#loading{
  .loading{
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100%;
    @include flex;
    .loading{
      background: rgba(0,0,0,0.4);
      height: 100%;
      width: 100%;
    }
    &>section{
      position: absolute;
    }
    .loading-bg{
      background: rgba(0,0,0,0.4);
      height: 100%;
      width: 100%;
    }
  }
  @keyframes ldio-xv3gnwmmwqr {
    0% {
      opacity: 1;
      backface-visibility: hidden;
      transform: translateZ(0) scale(1.5,1.5);
    } 100% {
      opacity: 0;
      backface-visibility: hidden;
      transform: translateZ(0) scale(1,1);
    }
  }
  .ldio-xv3gnwmmwqr div > div {
    position: absolute;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: $c1;
    animation: ldio-xv3gnwmmwqr 1s linear infinite;
  }.ldio-xv3gnwmmwqr div:nth-child(1) > div {
    left: 148px;
    top: 88px;
    animation-delay: -0.875s;
  }
  .ldio-xv3gnwmmwqr > div:nth-child(1) {
    transform: rotate(0deg);
    transform-origin: 160px 100px;
  }.ldio-xv3gnwmmwqr div:nth-child(2) > div {
    left: 130px;
    top: 130px;
    animation-delay: -0.75s;
  }
  .ldio-xv3gnwmmwqr > div:nth-child(2) {
    transform: rotate(45deg);
    transform-origin: 142px 142px;
  }.ldio-xv3gnwmmwqr div:nth-child(3) > div {
    left: 88px;
    top: 148px;
    animation-delay: -0.625s;
  }
  .ldio-xv3gnwmmwqr > div:nth-child(3) {
    transform: rotate(90deg);
    transform-origin: 100px 160px;
  }.ldio-xv3gnwmmwqr div:nth-child(4) > div {
    left: 46px;
    top: 130px;
    animation-delay: -0.5s;
  }
  .ldio-xv3gnwmmwqr > div:nth-child(4) {
    transform: rotate(135deg);
    transform-origin: 58px 142px;
  }.ldio-xv3gnwmmwqr div:nth-child(5) > div {
    left: 28px;
    top: 88px;
    animation-delay: -0.375s;
  }
  .ldio-xv3gnwmmwqr > div:nth-child(5) {
    transform: rotate(180deg);
    transform-origin: 40px 100px;
  }.ldio-xv3gnwmmwqr div:nth-child(6) > div {
    left: 46px;
    top: 46px;
    animation-delay: -0.25s;
  }
  .ldio-xv3gnwmmwqr > div:nth-child(6) {
    transform: rotate(225deg);
    transform-origin: 58px 58px;
  }.ldio-xv3gnwmmwqr div:nth-child(7) > div {
    left: 88px;
    top: 28px;
    animation-delay: -0.125s;
  }
  .ldio-xv3gnwmmwqr > div:nth-child(7) {
    transform: rotate(270deg);
    transform-origin: 100px 40px;
  }.ldio-xv3gnwmmwqr div:nth-child(8) > div {
    left: 130px;
    top: 46px;
    animation-delay: 0s;
  }
  .ldio-xv3gnwmmwqr > div:nth-child(8) {
    transform: rotate(315deg);
    transform-origin: 142px 58px;
  }
  .loadingio-spinner-spin-a3vnr2pg3ji {
    width: 200px;
    height: 200px;
    display: inline-block;
    overflow: hidden;

  }
  .ldio-xv3gnwmmwqr {
    width: 100%;
    height: 100%;
    position: relative;
    transform: translateZ(0) scale(1);
    backface-visibility: hidden;
    transform-origin: 0 0; /* see note above */
  }
  .ldio-xv3gnwmmwqr div { box-sizing: content-box; }
}
