.login {
  @include flex;
  flex-wrap: wrap;
  &>header {
    height: 4vh;
    width: 100%;
    background: $c1;
    @include flex;
    flex-wrap: wrap;
  }

  &>article {
    height: 96vh;
    width: 100%;
    aside {
      width: 100%;
      @include flex;
      flex-wrap: wrap;
      header {
        width: 80%;
        @include flex;
        img {}
      }
      article {
        h3 {
          font-weight: 400
        }
      }
    }

    &>section {
      width: 100%;
      @include flex;
      flex-wrap: wrap;
      form {
        padding: 30px 5%;
        border-radius: 20px;
        header {
          margin-bottom: 10px;
          div {
            font-size: 1.4em;
            font-weight: 400;
            cursor: default;
            span{
              cursor: pointer;
              &:first-child{
                font-weight: bold;
              }
            }
          }
        }
        background: $c1;
        width: 80%;
        div {
          width: 100%;
          height: 50px;
          margin-bottom: 15px;
          input {
            width: 100%;
            height: 50px;
            border: 1px solid black;
            padding-left: 15px;
          }
        }
        footer {
          background: $c1;
          @include flex;
          margin-top: 30px;
          .btn1 {}
        }
      }

    }
  }
}

@media screen and (max-width: 1600px) {}

@media screen and (max-width: 1400px) {}

@media screen and (max-width: 1300px) {}

@media screen and (max-width: 1200px) {}

@media screen and (max-width: 1024px) {
  .login > article > section form > div{
    flex-wrap: wrap;
    height: 80px;
    label{
      width: 100%
    }
  }
}

@media screen and (max-width: 900px) {
}

@media screen and (max-width: 760px) {
  .App > section > article{
    flex-wrap: wrap;
    align-items: flex-start;
  }
  .login > article > aside{
    margin-top: 50px;
  }
  .login > article > section{
  }
}

@media screen and (max-width: 600px) {
  .login{
    .logo{
      font-size: 9px
    }
  }
  .login > article aside article h3{
    font-size: 17px
  }
}

@media screen and (max-width: 550px) {}

@media screen and (max-width: 400px) {}

@media screen and (max-width: 320px) {}
