
#modal{  
  .modal{
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    @include flex;
    .modal-bg{
      background: rgba(0,0,0,0.4);
      height: 100%;
      width: 100%;
    }
    label{
      font-size: 1em
    }
    &>section{
      width: 90%;
      background: white;
      position: absolute;
      border-radius: 5px;
      &>header{
        height: 100px;
        @include flex;
        padding: 0 5%;
        height: 60px;
        border-bottom: 1px solid #BDBDBD;
        h2{
          width: 100%;
          text-align: left;
          text-transform: uppercase;
          font-size: 1.2em
        }
        &>div{
          width: 30px;
          height: 30px;
          @include flex;
          cursor: pointer;
        }
      }
      &>form,
      &>section
      {
        padding: 0;
        &>article{
          max-height: calc(100vh - 350px);
          overflow: auto;
          padding: 20px 0 0 0;
          &>*{
            padding: 0 7%;
          }
          &>.form-group{
            @include flex;
            width: 100%;
            label{
              width: 20%;
              padding-right: 20px;
              white-space: nowrap;
            }
            input{
              width: 80%
            }
            &:last-child{
              margin: 0
            }
          }
          .form-group{
            width: 100%;
            .form-group{
              width: 100%
            }
          }
        }
      }
      &>footer{
        height: 100px;
        @include flex;
        height: 60px;
        .footer_btns_cancel{
          background: blue;
          color: white;
          i{
            &:before{
              color: blue;
            }
          }
        }
        .footer_btns_save{
          color: white;
          background: blue;
        }
      }
    }
    .modal-login{
      padding: 0 5%;
      &>div{
        @include flex
        flex-wrap: wrap;
        margin-bottom: 20px;
        label{
          font-size: 1em;
          width: 100%
        }
        input{
          width: 100%;
          height: 30px;
          padding-left: 2%;
        }
      }
    }
  }
}
